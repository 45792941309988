import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Store/storeSlice";
import Basic from "./Basic";
import CurbSide from "./CurbSide";
import StoreHours from "./StoreHours";
import countryData from "../../../../utils/components/countryCode";
import { createStore } from "./api";
import { toast } from "react-toastify";
import useValidations from "../../../../utils/hooks/useValidations";
import Delivery from "./Delivery";

const useAddStore = (refetch, handleCreateClick) => {
  const [moveToTab, setMoveToTab] = useState(false);
  const [isStoreCreate, setIsStoreCreate] = useState(false);
  const dispatch = useDispatch();
  const { arabicRegex, englishRegex } = useValidations();
  const { isEdit, activeTab, editStoreData, curbTimeError, pickupTimeError } =
    useSelector((state) => state.store);

  const initialValues = {
    name: "",
    address_type: "",
    building_no: "",
    address_line1: "",
    address_line2: "",
    address_line3: "",
    pincode: "",
    alias_address_type: "",
    alias_building_no: "",
    alias_address_line1: "",
    alias_address_line2: "",
    alias_address_line3: "",
    alias_pincode: "",
    alias_name: "",
    about_us: "",
    alias_about_us: "",
    store_code: "",
    dial_code: "+965",
    contact_number: "",
    sec_dial_code: "+965",
    sec_contact_number: "",
    pick_up: 2,
    curb_side: 2,
    delivery: 2,
    image_url: "",
    location_longitude: "",
    location_latitude: "",
    location_name: "",
    alias_location_name: "",
    customer_key: "",
    qubriux_api_key: "",
    qubriux_customer_key: "",
    status: 1,
    pickup_hours: {},
    pickup_note: "",
    alias_pickup_note: "",
    curbside_hours: {},
    curbside_note: "",
    alias_curbside_note: "",
    curbside_info: "",
    alias_curbside_info: "",
    delivery_hours: {},
    delivery_note: "",
    alias_delivery_note: "",
    delivery_info: "",
    alias_delivery_info: "",
  };
  const commonStringValidation = Yup.string()
    .trim()
    .max(300, "Value must be less than 300 characters");

  const validPincodeValidation = Yup.string()
    .trim()
    .matches(/^[0-9][0-9]{4}$/, "Enter Valid Pin Code")
    .max(5, `Enter Valid Pin Code`);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .matches(englishRegex, "Enter in english letters")
      .required("Enter Store Name")
      .max(80, "Enter Valid Store Name"),
    alias_name: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters")
      .required("Enter Store Name")
      .max(80, "Enter Valid Store Name"),
    image_url: Yup.string().required("Upload Image"),
    address_type: commonStringValidation.required("Enter Address"),
    address_line1: commonStringValidation.required("Enter Address line 1"),
    // address_line2: commonStringValidation.required("Enter Address line 2"),
    // address_line3: commonStringValidation.required("Enter Address line 3"),
    alias_address_type: commonStringValidation
      .required("Enter Address")
      .matches(arabicRegex, "Enter in arabic letters"),
    alias_address_line1: commonStringValidation
      .required("Enter Address line 1")
      .matches(arabicRegex, "Enter in arabic letters"),
    dial_code: Yup.string().required("Select Country Code"),
    sec_dial_code: Yup.string().required("Select Country Code"),
    building_no: Yup.string().trim().required("Enter Building Number"),
    alias_building_no: Yup.string().trim().required("Enter Building Number"),
    alias_pincode: validPincodeValidation.required("Enter Pin code"),
    // .matches(arabicRegex, "Enter in arabic letters"),
    pincode: validPincodeValidation.required("Enter Pin code"),
    contact_number: Yup.string()
      .required("Enter Mobile Number")
      .min(7, "Enter Valid Mobile Number")
      .max(14, "Enter Valid Mobile Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/[0-9 ]+/, "Enter Valid Number"),
    sec_contact_number: Yup.string()
      .required("Enter Secondary Mobile Number")
      .min(7, "Enter Valid Secondary Mobile Number")
      .max(14, "Enter Valid Secondary Mobile Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Secondary Mobile Number")
      .notOneOf([Yup.ref("contact_number")], "Both Numbers cannot be same"),
    alias_location_name: Yup.string()
      .trim()
      .required("Enter location name")
      .matches(arabicRegex, "Enter in arabic letters")
      .max(300, "Enter valid location name"),
    location_name: Yup.string()
      .trim()
      .required("Enter location name")
      .matches(englishRegex, "Enter valid location name"),
    location_longitude: Yup.string()
      .required("Enter a valid Longitude")
      .min(-180, "Longitude must be between -180 and 180")
      .max(180, "Longitude must be between -180 and 180"),
    location_latitude: Yup.string()
      .required("Enter a valid Latitude")
      .min(-90, "Latitude must be between -90 and 90")
      .max(90, "Latitude must be between -90 and 90"),
    about_us: commonStringValidation.required("Enter About us"),
    alias_about_us: commonStringValidation
      .required("Enter About us")
      .matches(arabicRegex, "Enter in arabic letters"),
    customer_key: Yup.string()
      .required("Enter customer key")
      .max(100, "Enter Valid customer key"),
    qubriux_api_key: Yup.string().required(`Enter valid qubriux key`),
    qubriux_customer_key: Yup.string().required(
      `Enter valid qubriux customer key`
    ),
    alias_pickup_note: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters"),
    alias_curbside_note: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters"),
    alias_curbside_info: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,

    onSubmit: (values) => {
      setIsStoreCreate(true);
      // if (!SpecificCurbTimeError && !SpecificpPickupTimeError) {
      let data = {
        name: values?.name,
        alias_name: values?.alias_name,
        address: {
          address_type: values?.address_type,
          building_no: values?.building_no,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          address_line3: values?.address_line3,
          pincode: values?.pincode,
        },
        alias_address: {
          address_type: values?.alias_address_type,
          building_no: values?.alias_building_no,
          address_line1: values?.alias_address_line1,
          address_line2: values?.alias_address_line2,
          address_line3: values?.alias_address_line3,
          pincode: values?.pincode,
        },
        about_us: values?.about_us,
        store_code: values?.store_code,
        alias_about_us: values?.alias_about_us,
        dial_code: values?.dial_code,
        sec_dial_code: values?.sec_dial_code,
        contact_number: values?.contact_number,
        sec_contact_number: values?.sec_contact_number,
        pick_up: values?.pick_up,
        curb_side: values?.curb_side,
        delivery: values?.delivery,
        image_url: values?.image_url,
        location_longitude: parseFloat(values?.location_longitude || 0),
        location_latitude: parseFloat(values?.location_latitude || 0),
        location_name: values?.location_name,
        alias_location_name: values?.alias_location_name,
        customer_key: values?.customer_key,
        qubriux_api_key: values?.qubriux_api_key,
        qubriux_customer_key: values?.qubriux_customer_key,
        pickup_hours: values?.pickup_hours,
        pickup_note: values?.pickup_note,
        alias_pickup_note: values?.alias_pickup_note,
        curbside_hours: values?.curbside_hours,
        curbside_note: values?.curbside_note,
        alias_curbside_note: values?.alias_curbside_note,
        curbside_info: values?.curbside_info,
        alias_curbside_info: values?.alias_curbside_info,
        delivery_hours: values?.delivery_hours,
        delivery_note: values?.delivery_note,
        alias_delivery_note: values?.alias_delivery_note,
        delivery_info: values?.delivery_info,
        alias_delivery_info: values?.alias_delivery_info,
        status: values?.status,
      };
      let newObj = Object.entries(data);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      if (!isEdit) {
        createStore(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Store created successfully`);
            handleCreateClick();
            refetch();
            setIsStoreCreate(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.error_code === 403 &&
            response?.data?.errors
          ) {
            Object.keys(response?.data?.errors).forEach((field) => {
              formik.setFieldError(field, response?.data?.errors[field]);
              handleMoveToErrorTab();
              setIsStoreCreate(false);
              toast.error(response?.data?.errors[field]);
            });
          } else {
            toast.error(`Something went wrong !`);
            handleCreateClick();
            setIsStoreCreate(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        });
      } else {
        let updateStoreData = { store_id: editStoreData?._id, ...subData };
        createStore(updateStoreData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Store Updated successfully`);
            handleCreateClick();
            setIsStoreCreate(false);
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (
            response?.data?.status_code === 422 &&
            response?.data?.errors
          ) {
            Object.keys(response?.data?.errors).forEach((field) => {
              formik.setFieldError(field, response?.data?.errors[field]);
              handleMoveToErrorTab();
              setIsStoreCreate(false);
              toast.error(response?.data?.errors[field]);
            });
          } else {
            toast.error(`Something went wrong !`);
            handleCreateClick();
            setIsStoreCreate(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        });
      }
      // }
    },
  });

  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  let countryCode = countryCodeOptions?.filter(
    (item) => item?.value === formik?.values?.dial_code
  );

  let countrySec_Code = countryCodeOptions?.filter(
    (item) => item?.value === formik?.values?.sec_dial_code
  );

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "name",
        "address_type",
        "building_no",
        "address_line1",
        "address_line2",
        "address_line3",
        "pincode",
        "alias_address_type",
        "alias_building_no",
        "alias_address_line1",
        "alias_address_line2",
        "alias_address_line3",
        "alias_pincode",
        "alias_name",
        "about_us",
        "store_code",
        "alias_about_us",
        "dial_code",
        "contact_number",
        "sec_dial_code",
        "sec_contact_number",
        "image_url",
        "location_longitude",
        "location_latitude",
        "location_name",
        "alias_location_name",
        "customer_key",
        "qubriux_api_key",
        "qubriux_customer_key",
        "status",
      ],
    },
    {
      label: "Store Hours",
      fields: ["pick_up", "pickup_hours", "pickup_note", "alias_pickup_note"],
    },
    {
      label: "Curbside Hours",
      fields: [
        "curb_side",
        "curbside_hours",
        "curbside_note",
        "alias_curbside_note",
        "curbside_info",
        "alias_curbside_info",
      ],
    },
    {
      label: "Delivery Hours",
      fields: [
        "delivery",
        "delivery_hours",
        "delivery_note",
        "alias_delivery_note",
        "delivery_info",
        "alias_delivery_info",
      ],
    },
  ];
  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = fields?.filter(
      (value) =>
        value !== "dial_code" &&
        value !== "sec_dial_code" &&
        value !== "status" &&
        value !== "pick_up" &&
        value !== "curb_side"
    );

    if (finalArray.includes("pickup_hours")) {
      // Check if at least one value exists in pickup_hours
      if (
        formik?.values.pickup_hours &&
        Object.keys(formik?.values.pickup_hours).length > 0
      ) {
        finalArray.push("pickup_hours");
      }
    }

    if (finalArray.includes("curbside_hours")) {
      // Check if at least one value exists in curbside_hours
      if (
        formik?.values.curbside_hours &&
        Object.keys(formik?.values.curbside_hours).length > 0
      ) {
        finalArray.push("curbside_hours");
      }
    }

    if (finalArray.includes("delivery_hours")) {
      // Check if at least one value exists in delivery hours
      if (
        formik?.values.delivery_hours &&
        Object.keys(formik?.values.delivery_hours).length > 0
      ) {
        finalArray.push("delivery_hours");
      }
    }

    const completedFields = finalArray?.filter((field) => {
      // Check if the field exists and is not an empty object
      return (
        formik?.values[field] && Object.keys(formik?.values[field]).length > 0
      );
    });

    return (completedFields.length / fields.length) * 125;
  }

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "name",
        "address_type",
        "building_no",
        "address_line1",
        "address_line2",
        "address_line3",
        "pincode",
        "alias_address_type",
        "alias_building_no",
        "alias_address_line1",
        "alias_address_line2",
        "alias_address_line3",
        "alias_pincode",
        "alias_name",
        "about_us",
        "store_code",
        "alias_about_us",
        "dial_code",
        "contact_number",
        "sec_dial_code",
        "sec_contact_number",
        "image_url",
        "location_longitude",
        "location_latitude",
        "location_name",
        "alias_location_name",
        "customer_key",
        "qubriux_api_key",
        "qubriux_customer_key",
        "status",
      ]),
    },
    {
      label: "Store Hours",
      completed: calculateCompletionStatus([
        "pick_up",
        "pickup_hours",
        "pickup_note",
        "alias_pickup_note",
      ]),
    },
    {
      label: "Curbside Hours",
      completed: calculateCompletionStatus([
        "curb_side",
        "curbside_hours",
        "curbside_note",
        "alias_curbside_note",
        "curbside_info",
        "alias_curbside_info",
      ]),
    },
    {
      label: "Delivery Hours",
      completed: calculateCompletionStatus([
        "delivery",
        "delivery_hours",
        "delivery_note",
        "alias_delivery_note",
        "delivery_info",
        "alias_delivery_info",
      ]),
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "Basic":
        return (
          <Basic
            formik={formik}
            tabs={tabs}
            countryCode={countryCode}
            countrySec_Code={countrySec_Code}
            countryCodeOptions={countryCodeOptions}
            getFieldError={getFieldError}
          />
        );
      case "Store Hours":
        return (
          <StoreHours
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
          />
        );
      case "Curbside Hours":
        return (
          <CurbSide
            formik={formik}
            getFieldError={getFieldError}
            tabs={tabs}
            isStoreCreate={isStoreCreate}
          />
        );
      case "Delivery Hours":
        return (
          <Delivery
            formik={formik}
            getFieldError={getFieldError}
            tabs={tabs}
            isStoreCreate={isStoreCreate}
          />
        );
      default:
        return null;
    }
  };

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    } else if (pickupTimeError) {
      const hasSpecificError = Object.values(pickupTimeError).some(
        (error) => error === "To time should be at least 1 hour after from time"
      );
      if (hasSpecificError) {
        setActiveTabByName("Store Hours");
        return;
      }
    } else if (curbTimeError) {
      const hasSpecificError = Object.values(curbTimeError).some(
        (error) => error === "To time should be at least 1 hour after from time"
      );
      if (hasSpecificError) {
        setActiveTabByName("Curbside Hours");
        return;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        name: editStoreData?.name ?? "",
        address_type: editStoreData?.address?.address_type ?? "",
        building_no: editStoreData?.address?.building_no ?? "",
        address_line1: editStoreData?.address?.address_line1 ?? "",
        address_line2: editStoreData?.address?.address_line3 ?? "",
        address_line3: editStoreData?.address?.address_line1 ?? "",
        pincode: editStoreData?.address?.pincode ?? "",
        alias_address_type: editStoreData?.alias_address?.address_type ?? "",
        alias_building_no: editStoreData?.alias_address?.building_no ?? "",
        alias_address_line1: editStoreData?.alias_address?.address_line1 ?? "",
        alias_address_line2: editStoreData?.alias_address?.address_line3 ?? "",
        alias_address_line3: editStoreData?.alias_address?.address_line1 ?? "",
        alias_pincode: editStoreData?.alias_address?.pincode ?? "",
        alias_name: editStoreData?.alias_name ?? "",
        about_us: editStoreData?.about_us ?? "",
        store_code: editStoreData?.store_code ?? "",
        alias_about_us: editStoreData?.alias_about_us ?? "",
        dial_code: editStoreData?.dial_code ?? "",
        contact_number: editStoreData?.contact_number ?? "",
        sec_dial_code: editStoreData?.sec_dial_code ?? "",
        sec_contact_number: editStoreData?.sec_contact_number ?? "",
        pick_up: editStoreData?.pick_up,
        curb_side: editStoreData?.curb_side,
        image_url: editStoreData?.image_url ?? "",
        location_longitude: editStoreData?.location_longitude ?? "",
        location_latitude: editStoreData?.location_latitude ?? "",
        location_name: editStoreData?.location_name ?? "",
        alias_location_name: editStoreData?.alias_location_name ?? "",
        customer_key: editStoreData?.customer_key ?? "",
        qubriux_api_key: editStoreData?.qubriux_api_key ?? "",
        qubriux_customer_key: editStoreData?.qubriux_customer_key ?? "",
        status: editStoreData?.status ?? 2,
        pickup_hours: editStoreData?.pickup_hours,
        pickup_note: editStoreData?.pickup_note,
        alias_pickup_note: editStoreData?.alias_pickup_note,
        curbside_hours: editStoreData?.curbside_hours,
        curbside_note: editStoreData?.curbside_note,
        alias_curbside_note: editStoreData?.alias_curbside_note,
        curbside_info: editStoreData?.curbside_info,
        alias_curbside_info: editStoreData?.alias_curbside_info,
        delivery: editStoreData?.delivery,
        delivery_hours: editStoreData?.delivery_hours,
        delivery_note: editStoreData?.delivery_note,
        alias_delivery_note: editStoreData?.alias_delivery_note,
        delivery_info: editStoreData?.delivery_info,
        alias_delivery_info: editStoreData?.alias_delivery_info,
      });
      dispatch(
        updateConfig((state) => {
          state.imagePreview = editStoreData?.image_full_url;
          state.imageName = editStoreData?.image_url?.split("/").pop();
        })
      );
    }

    // eslint-disable-next-line
  }, [isEdit, editStoreData]);

  return {
    tabs,
    activeTab,
    formik,
    renderTabContent,
    handleTabClick,
    handleMoveToErrorTab,
  };
};

export default useAddStore;
