import {
  useGetDeliveryAreasDataQuery,
  useDeleteDeliveryAreaDataMutation,
  useUpdateDeliveryAreasStatusMutation,
  useUpdateDeliveryAreasTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeliveryAreasEditData,
  updateConfig,
} from "../../../store/slices/Admin/DeliveryAreas/deliveryAreasSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { admin } from "../../../store/queries/Admin";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const useDeliveryAreas = () => {
  const dispatch = useDispatch();
  const deliveryAreasState = useSelector((state) => state.deliveryAreas);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateDeliveryAreasStatus] = useUpdateDeliveryAreasStatusMutation();
  const [deleteDeliveryAreaData] = useDeleteDeliveryAreaDataMutation();
  const [updateDeliveryAreasTableHeadData] =
    useUpdateDeliveryAreasTableHeadDataMutation();
  const {
    data: deliveryAreasList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetDeliveryAreasDataQuery({
    page_size: deliveryAreasState?.currentPageSize,
    page: deliveryAreasState?.currentPage,
    sort: deliveryAreasState?.sortBy,
    sort_order: deliveryAreasState?.sortOrder,
    search: deliveryAreasState?.search,
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              onChange={(e) => handleToggle(e, data)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleToggle = (e, data) => {
    const newStatus = data.status === 2 ? true : false;
    let body = {
      area_id: data?._id,
      status: newStatus ? 1 : 2,
    };
    updateDeliveryAreasStatus(body);
    dispatch(
      admin.util.updateQueryData(
        "getDeliveryAreasData",
        {
          page_size: deliveryAreasState?.currentPageSize,
          page: deliveryAreasState?.currentPage,
          sort: deliveryAreasState?.sortBy,
          sort_order: deliveryAreasState?.sortOrder,
          search: deliveryAreasState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.result?.map((res) =>
            res._id === data?._id
              ? {
                  ...res,
                  status: data.status === 2 ? 1 : 2,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              result: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleSort = (label) => {
    if (deliveryAreasState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            deliveryAreasState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.isEdit = false;
      })
    );
  };

  const handleCloseForm = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.editDeliveryDetailsData = {};
      })
    );
  };

  const handleEditAction = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = id?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getDeliveryAreasEditData(id?.[0]));
  };

  const handleDeleteAction = (id) => {
    const deleteId = id?.[0];
    dispatch(
      updateConfig((state) => {
        state.selectedId = deleteId;
        state.deleteConfirmationModal = true;
      })
    );
  };

  const handleCloseDeleteModal = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedId = "";
        state.deleteConfirmationModal = false;
      })
    );
  };

  const handleSubmitDelete = () => {
    deleteDeliveryAreaData({ area_id: deliveryAreasState?.selectedId }).then(
      (res) => {
        if (res?.data?.status_code === 200) {
          toast.success("Delivery area deleted successfully");
          handleCloseDeleteModal();
          refetch();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
        } else {
          toast.error("Failed to delete delivery area");
          handleCloseDeleteModal();
        }
      }
    );
  };

  const handleClickExportButton = () => {
    dispatch(
      updateConfig((state) => {
        state.exportModal = true;
      })
    );
  };

  const handleCloseExportModal = () => {
    dispatch(
      updateConfig((state) => {
        state.exportModal = false;
        state.imagePreview = "";
        state.imageName = "";
      })
    );
  };

  return {
    updateDeliveryAreasTableHeadData,
    isLoading,
    isFetching,
    deliveryAreasList,
    paginationOptions,
    deliveryAreasState,
    showEditModal,
    refetch,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handleCloseForm,
    handlePagination,
    handleEditAction,
    handleClearClick,
    handleCreateClick,
    handleDeleteAction,
    handleSubmitDelete,
    handleCloseDeleteModal,
    handleEditColumnsClick,
    handleCloseExportModal,
    handleClickExportButton,
  };
};

export default useDeliveryAreas;
