import { useSelector } from "react-redux";
import { useGetStatusDataQuery } from "../../../../store/queries/Dashboard";
import { getFormatedDate } from "../../../../utils/Functions/table";
import { useMemo } from "react";

const useChartData = () => {
  const globalState = useSelector((state) => state.global);
  const { data: statusData = {}, isFetching: statusDataFetching } =
    useGetStatusDataQuery({
      start_date: getFormatedDate(globalState?.startDate),
      end_date: getFormatedDate(globalState?.endDate),
      graph_type: globalState?.chartFilterType,
      customer_key: globalState?.customerKey ?? "",
    });

  // status order line graph
  const orderData = {
    labels: statusData?.data?.labels,
    datasets: [
      {
        label: "My First Dataset",
        data: statusData?.data?.datasets?.data ?? "",
        fill: false,
        borderColor: "#0CB4CD",
        pointRadius: 0, // Set the point radius to 0
        pointHoverRadius: 0, // Set the hover radius to 0
        drawActiveElementsOnTop: true,
      },
    ],
  };
  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart, args, options) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      if (tooltip._active.length > 0) {
        const xPos = tooltip.dataPoints[0].dataIndex;
        const yPos = tooltip.dataPoints[0].parsed.y;

        ctx.save();
        ctx.lineWidth = 3;
        ctx.strokeStyle = "rgba(0,0,0,0.1";
        ctx.moveTo(left, top);
        ctx.lineTo(left, bottom);
        ctx.stroke();
      }
    },
  };
  const orderGraph = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            return "";
          },
          title: function (tooltipItems) {
            return "";
          },
          afterLabel: function (tooltipItem) {
            return tooltipItem.formattedValue;
          },
        },
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        bodyFontStyle: "bold",
        position: "nearest",
        displayColors: false,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
        caretSize: 5,
        cornerRadius: 8,
      },
      hoverLine,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  // status sale bar graph
  const saleData = {
    labels: statusData?.data?.labels ?? "",
    datasets: [
      {
        label: statusData?.data?.datasets?.applepay?.label ?? "",
        data: statusData?.data?.datasets?.applepay?.data ?? "",
        backgroundColor: "#7AD3DF",
      },
      {
        label: statusData?.data?.datasets?.googlepay?.label ?? "",
        data: statusData?.data?.datasets?.googlepay?.data ?? "",
        backgroundColor: "#6E7BF2",
      },
      {
        label: statusData?.data?.datasets?.card?.label ?? "",
        data: statusData?.data?.datasets?.card?.data ?? "",
        backgroundColor: "#F9F885",
      },
      {
        label: statusData?.data?.datasets?.wallet?.label ?? "",
        data: statusData?.data?.datasets?.wallet?.data ?? "",
        backgroundColor: "#2C687E",
      },
      {
        label: statusData?.data?.datasets?.knet?.label ?? "",
        data: statusData?.data?.datasets?.knet?.data ?? "",
        backgroundColor: "#4CA896",
      },
      {
        label: statusData?.data?.datasets?.redeem?.label ?? "",
        data: statusData?.data?.datasets?.redeem?.data ?? "",
        backgroundColor: "#B5E287",
      },
    ],
  };

  const saleGraph = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          footer: function (tooltipItems, data) {
            const newTotal = tooltipItems?.reduce((total, item) => {
              const intValue = parseFloat(
                item?.formattedValue.replace(/,/g, ""),
                10
              );
              return total + intValue;
            }, 0);
            return "Total: " + newTotal.toFixed(2) + " KD";
          },
          afterLabel: function (tooltipItem) {
            return " ";
          },

          afterTitle: function (tooltipItem) {
            return " ";
          },
          label: function (tooltipItem) {
            return `${tooltipItem?.dataset?.label} : ${tooltipItem?.formattedValue} KD`;
          },
        },
        backgroundColor: "white",
        titleColor: "#767676",
        bodyColor: "#767676",
        footerColor: "#767676",
        bodyFont: {
          weight: 500,
          size: 13,
        },
        position: "nearest",
        displayColors: true,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
        caretSize: 5,
        cornerRadius: 4,
        padding: 8,
        multiKeyBackground: "#ffffff",
      },
      title: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    barPercentage: 1.0,
    categoryPercentage: 0.3,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  const basicSaleData = {
    labels: statusData?.data?.labels ?? "",
    datasets: [
      {
        label: statusData?.data?.datasets?.purchase?.label ?? "",
        data: statusData?.data?.datasets?.purchase?.data ?? "",
        backgroundColor: "#7AD3DF",
      },
      {
        label: statusData?.data?.datasets?.redeem?.label ?? "",
        data: statusData?.data?.datasets?.redeem?.data ?? "",
        backgroundColor: "#F9F885",
      },
    ],
  };
  //  Status user bar graph
  const userData = {
    labels: statusData?.data?.labels ?? [],
    datasets: [
      {
        label: statusData?.data?.datasets?.android?.label ?? "",
        data: statusData?.data?.datasets?.android?.data ?? [],
        backgroundColor: "#7AD3DF",
      },
      {
        label: statusData?.data?.datasets?.ios?.label ?? "",
        data: statusData?.data?.datasets?.ios?.data ?? [],
        backgroundColor: "#2C687E",
      },
    ],
  };

  const userGraph = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          footer: function (tooltipItems, data, index) {
            const newTotal = tooltipItems?.reduce((total, item) => {
              const intValue = parseFloat(
                item?.formattedValue.replace(/,/g, ""),
                10
              );
              return total + intValue;
            }, 0);
            return "Total: " + newTotal;
          },
          afterLabel: function (tooltipItem, index) {
            return " ";
          },
          afterTitle: function (tooltipItem) {
            return " ";
          },
        },
        backgroundColor: "white",
        titleColor: "#767676",
        bodyColor: "#767676",
        footerColor: "#767676",
        bodyFont: {
          weight: 500,
          size: 13,
        },
        position: "nearest",
        displayColors: true,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
        caretSize: 5,
        cornerRadius: 4,
        padding: 8,
        multiKeyBackground: "#ffffff",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    barPercentage: 1.0,
    categoryPercentage: 0.3,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  // Sales Status graph
  const doughnut = [
    {
      label: "Cappuccino",
      percent: 25,
      value: 25,
    },
    {
      label: "Frappuccino",
      percent: 25,
      value: 25,
    },
    {
      label: "Cold coffee",
      percent: 15,
      value: 15,
    },
    {
      label: "Others",
      percent: 10,
      value: 10,
    },
    {
      label: "Burgers",
      percent: 25,
      value: 25,
    },
  ];

  const doughnutData = {
    labels: doughnut.map(
      (item) =>
        `${item?.label?.charAt(0).toUpperCase()}${item?.label?.slice(1)} ${
          item?.percent
        }%`
    ),
    datasets: [
      {
        label: "My First Dataset",
        data: [25, 25, 15, 10, 25],
        backgroundColor: [
          "#4CA896",
          "#2C687E",
          "#7AD3DF",
          "#F9F885",
          "#B5E287",
        ],
        hoverOffset: 2,
      },
    ],
  };

  const doughnutGraph = {
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#767676",
        bodyColor: "#767676",
        footerColor: "#767676",
        bodyFont: {
          weight: 500,
          size: 13,
        },
        position: "nearest",
        displayColors: true,
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
        caretSize: 5,
        cornerRadius: 4,
        padding: 8,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  const chartDataMap = useMemo(() => {
    return {
      sale: {
        labels: saleData?.labels,
        datasets: saleData?.datasets,
        options: saleGraph,
      },
      user: {
        labels: userData?.labels,
        datasets: userData?.datasets,
        options: userGraph,
      },
      order: {
        labels: orderData?.labels,
        datasets: orderData?.datasets,
        options: orderGraph,
      },
      basic_sale: {
        labels: basicSaleData?.labels,
        datasets: basicSaleData?.datasets,
        options: saleGraph,
      },
    };
    //eslint-disable-next-line
  }, [statusData]);

  return {
    doughnutGraph,
    doughnutData,
    doughnut,
    chartDataMap,
    statusDataFetching,
  };
};

export default useChartData;
