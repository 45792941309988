const InputShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className="shimmer pro-px-6 pro-py-1 pro-mt-3 pro-mb-1"></div>
      <div className="shimmer letter pro-py-4 pro-rounded-3"></div>
    </div>
  );
};

export default InputShimmer;
